import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";
import hero_image from "../../assets/Sudcover-removebg-preview.png";
import hero_back from "../../assets/hero_image_back.png";
import calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";
import Header from "../Header/Header";
import { Link } from "react-scroll";

const Hero = () => {
  const navigate = useNavigate();
  const transition = { duration: 3, type: "spring" };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>

      <div className="left-h">
        <Header />

        <div className="the-best">
          <motion.div
            initial={{ left: isMobile ? "178px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>Fitness is Simple, Not Easy!</span>
        </div>

        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>

        <div className="figures">
          <div>
            <span>
              <NumberCounter end={11} start={2} delay={4} postFix="+" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter end={50} start={2} delay={4} postFix="+" />
            </span>
            <span>Members joined</span>
          </div>
          <div>
            <span>
              <NumberCounter end={8} delay={2} postFix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>

        <div className="home-btns">
          <Link className="jbtn" to="contact" spy={true} smooth={true}>
            Join now
          </Link>
          <button
            className="cbtn"
            onClick={() => navigate("/fitness-explained")}
          >
            Fitness Explained
          </button>
          <button
            className="cbtn"
            onClick={() => navigate("/calculate-yourself")}
          >
            Calculate your Body Metrics
          </button>
        </div>
      </div>

      <img className="hero-img" src={hero_image} alt="" />
      <img className="hero-back" src={hero_back} alt="" />

      <div
        className={`calories ${
          isMobile ? "mobile-calories" : "desktop-calories"
        }`}
      >
        <img src={calories} alt="" />
        <div>
          <span>Track Your</span>
          <span>Fitness</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
