import { Routes, Route } from "react-router-dom";
import "./App.css";
import Hero from "./Components/Hero/Hero";
import Plans from "./Components/Plans/Plans";
import Programs from "./Components/Programs/Programs";
import Reasons from "./Components/Reasons/Reasons";
import Testimonials from "./Components/Testimonials/Testimonials";
import Footer from "./Components/Footer/Footer";
import Contact from "./Components/Contact/Contact";
import CalculateYourself from "./Components/CalculateYourself/calculateyourself";
import FitnessCards from "./Components/FitnessExplained/fitnessexplained";
import PrivacyPolicy from "./Components/PrivacyPolicy/privacypolicy";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Programs />
              <Reasons />
              <Plans />
              <Testimonials />
              <Contact />
              <Footer />
            </>
          }
        />

        <Route path="/calculate-yourself" element={<CalculateYourself />} />
        <Route path="/fitness-explained" element={<FitnessCards />} />
        <Route path="/privacy-and-legal-policies" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
