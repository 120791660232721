import React from "react";
import "./privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy">
        <h1>Privacy Policy & Legal Policy</h1>
        <p className="effective-date">
          <strong>Effective Date:</strong> 01st January 2025
        </p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to <strong>Fitness Engineers</strong>. Your privacy and
            trust are important to us. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you
            visit our website <strong>fitnessengineers.in</strong> (the
            "Website"). By using our services, you agree to the terms of this
            Privacy Policy.
          </p>
          <div className="contact-info">
            <p>
              <strong>Contact Us:</strong>
            </p>
            <ul>
              <li>
                <strong>Email:</strong> fitnessengineers@gmail.com /
                sudhakar12793@gmail.com
              </li>
              <li>
                <strong>Country:</strong> India
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <h3>a. Personal Information</h3>
          <ul>
            <li>Name</li>
            <li>Email Address</li>
            <li>Phone Number</li>
            <li>Payment Information (for purchasing plans)</li>
            <li>Age, gender, fitness goals (if provided voluntarily)</li>
          </ul>
          <h3>b. Non-Personal Information</h3>
          <ul>
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Pages visited and browsing behavior</li>
            <li>Device information</li>
            <li>Cookies and tracking technologies</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <ul>
            <li>To provide and improve our nutrition and workout plans</li>
            <li>To process payments securely</li>
            <li>To communicate about services, offers, and updates</li>
            <li>To analyze website performance</li>
            <li>To comply with legal requirements</li>
          </ul>
        </section>

        <section>
          <h2>4. Sharing Your Information</h2>
          <p>We do not sell or rent personal data but may share it with:</p>
          <ul>
            <li>
              <strong>Service Providers:</strong> Payment gateways, analytics,
              and hosting services
            </li>
            <li>
              <strong>Legal Authorities:</strong> If required by law
            </li>
            <li>
              <strong>Business Partners:</strong> For fitness-related services
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Data Security</h2>
          <p>
            We take security measures to protect personal information. However,
            no system is 100% secure.
          </p>
        </section>

        <section>
          <h2>6. Cookies & Tracking Technologies</h2>
          <p>
            We use cookies to enhance user experience. You can disable cookies
            in browser settings.
          </p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>You may have the right to:</p>
          <ul>
            <li>Access, correct, or delete your data</li>
            <li>Opt-out of marketing emails</li>
            <li>Withdraw consent for data processing</li>
          </ul>
          <p>
            Email us at <strong>fitnessengineers@gmail.com</strong> to exercise
            these rights.
          </p>
        </section>

        <section>
          <h2>8. Third-Party Links</h2>
          <p>We are not responsible for third-party website policies.</p>
        </section>

        <section>
          <h2>9. Legal Policy</h2>
          <h3>a. Terms of Use</h3>
          <ul>
            <li>Use content for personal, non-commercial purposes</li>
            <li>No unlawful activities</li>
            <li>
              Do not copy, distribute, or modify content without permission
            </li>
          </ul>
          <h3>b. Disclaimers</h3>
          <p>
            We do not provide medical advice. Consult a healthcare professional
            before following our plans.
          </p>
          <h3>c. Limitation of Liability</h3>
          <p>
            Fitness Engineers is not liable for damages arising from the use of
            our services.
          </p>
          <h3>d. Governing Law</h3>
          <p>This policy is governed by the laws of India.</p>
        </section>

        <section>
          <h2>10. Changes to This Policy</h2>
          <p>
            We may update this Privacy and Legal Policy. Changes will be posted
            with an updated effective date.
          </p>
        </section>

        <section className="contact-us">
          <h2>Contact Us</h2>
          <p>
            <strong>Email:</strong> fitnessengineers@gmail.com /
            sudhakar12793@gmail.com
          </p>
          <p>
            <strong>Website:</strong>{" "}
            <a href="https://fitnessengineers.in">fitnessengineers.in</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
