export const fitnessTopics = [
    {
        title: "Strength 💪",
        why: "Strength is essential for performing daily activities like lifting, carrying, and maintaining posture. It helps prevent injuries, improves joint stability, and enhances overall body mechanics.",
        who: "Athletes: Weightlifters, wrestlers, rugby players, footballers, and sprinters. Normal Individuals: Helps in everyday tasks, prevents age-related muscle loss, and boosts metabolism.",
        how: "Resistance training (weightlifting, bodyweight exercises like push-ups and squats). Compound movements like deadlifts, bench presses, and pull-ups.",
        levelUp: "Gradually increase weights (progressive overload). Focus on proper form and recovery. Incorporate variations (e.g., drop sets, supersets)."
    },
    {
        title: "Stamina 🏃‍♂️",
        why: "Improves the ability to sustain physical effort for longer durations. Enhances cardiovascular health, reduces fatigue, and boosts overall endurance.",
        who: "Athletes: Long-distance runners, swimmers, cyclists, and footballers. Normal Individuals: Helps in daily activities like walking, climbing stairs, and prolonged physical tasks.",
        how: "Aerobic exercises: Running, swimming, cycling. HIIT (High-Intensity Interval Training) to build endurance quickly.",
        levelUp: "Increase workout intensity and duration gradually. Focus on breathing techniques and consistent training. Incorporate interval training to improve heart rate efficiency."
    },
    {
        title: "Agility 🏃‍♂️💨",
        why: "Enhances reaction time, quick movements, and coordination. Prevents falls and injuries by improving body control.",
        who: "Athletes: Basketball players, footballers, tennis players, and martial artists. Normal Individuals: Useful for quick reflexes, better mobility, and balance.",
        how: "Ladder drills, cone drills, and plyometrics (box jumps, sprint drills). Sports that require quick direction changes like soccer or tennis.",
        levelUp: "Increase complexity and speed of agility drills. Use resistance bands and weighted vests for added challenge. Improve mind-muscle coordination through reaction drills."
    },
    {
        title: "Flexibility 🤸‍♀️",
        why: "Reduces the risk of injuries and muscle stiffness. Enhances posture, mobility, and overall movement efficiency.",
        who: "Athletes: Gymnasts, dancers, martial artists, and swimmers. Normal Individuals: Reduces back pain, improves posture, and enhances daily movement.",
        how: "Regular stretching routines (static & dynamic stretching). Yoga and Pilates to enhance flexibility and core strength.",
        levelUp: "Hold stretches for longer durations (30-60 seconds). Gradually increase the range of motion. Perform active flexibility exercises like leg swings and deep lunges."
    },
    {
        title: "Endurance 🏃‍♀️",
        why: "Helps sustain physical activity for extended periods without fatigue. Improves heart and lung efficiency.",
        who: "Athletes: Marathon runners, swimmers, cyclists, and rowers. Normal Individuals: Helps in prolonged activities like hiking and physical labor.",
        how: "Cardiovascular exercises like jogging, swimming, and cycling. Strength-endurance workouts like circuit training and bodyweight exercises.",
        levelUp: "Increase workout duration and intensity gradually. Focus on consistent training and active recovery. Use breathing techniques to improve oxygen intake."
    },
    {
        title: "Hypertrophy (Muscle Growth) 💪🔝",
        why: "Increases muscle size, strength, and overall physique. Improves metabolism and functional strength.",
        who: "Athletes: Bodybuilders, powerlifters, and fitness models. Normal Individuals: Helps maintain muscle mass with age and improves body composition.",
        how: "Resistance training with moderate to heavy weights. Focus on high-volume training (8-12 reps per set).",
        levelUp: "Increase weights progressively. Follow a high-protein diet. Optimize recovery (sleep & proper rest days)."
    },
    {
        title: "Immunity 🛡️",
        why: "Strengthens the body’s defense against infections and diseases. Reduces inflammation and speeds up recovery.",
        who: "Everyone: Immunity is crucial for overall health and longevity. Athletes: Helps in quicker recovery and prevents training-related illnesses.",
        how: "Regular exercise (moderate-intensity workouts boost immunity). Eating a nutrient-rich diet (vitamins C & D, zinc, antioxidants). Getting enough sleep and managing stress.",
        levelUp: "Include immune-boosting foods (citrus fruits, nuts, yogurt). Stay hydrated and avoid overtraining. Maintain gut health with probiotics."
    },
    {
        title: "Speed 🏃‍♂️⚡",
        why: "Enhances quick movement and reaction time. Useful in sports, daily activities, and self-defense situations.",
        who: "Athletes: Sprinters, footballers, boxers, and martial artists. Normal Individuals: Helps in quick responses and functional fitness.",
        how: "Sprint training, resistance sprints, and explosive workouts. Plyometric drills (jump squats, bounding exercises).",
        levelUp: "Work on explosive strength (fast-twitch muscle activation). Use resistance bands for acceleration training. Optimize stride length and running mechanics."
    },
    {
        title: "Balance & Coordination ⚖️",
        why: "Reduces the risk of falls and improves movement efficiency. Enhances sports performance and daily stability.",
        who: "Athletes: Gymnasts, surfers, skiers, and dancers. Normal Individuals: Helps in posture correction and injury prevention.",
        how: "Balance board exercises, single-leg drills, and core strengthening. Yoga and stability ball exercises.",
        levelUp: "Increase difficulty with unstable surfaces. Perform coordination drills (hand-eye coordination exercises)."
    },
    {
        title: "Recovery & Rest 😴",
        why: "Essential for muscle repair, strength gain, and mental well-being. Prevents overtraining, burnout, and injuries.",
        who: "Athletes: Faster recovery = better performance. Normal Individuals: Prevents fatigue, stress, and improves overall health.",
        how: "Prioritize sleep (7-9 hours per night). Include rest days and active recovery (light stretching, walking). Hydration and proper nutrition for muscle repair.",
        levelUp: "Improve sleep quality (avoid screens before bed, maintain a sleep schedule). Use foam rolling and massage for muscle recovery. Track rest and adjust workout intensity accordingly."
    }
];