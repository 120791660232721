import React, { useState } from "react";
import "./fitnessexplained.css";
import { fitnessTopics } from "./FitnessTopics";
import Logo from "../../assets/FEStamp.png";
import { useLocation, useNavigate } from "react-router-dom";

const FitnessCards = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div className="fitness-container">
      <div className="logoContainer">
        <img src={Logo} alt="Logo" className="logo" onClick={handleLogoClick} />
        <h2>What is Fitness</h2>
      </div>

      <p className="fitness-intro">
        Fitness is a combination of various physical attributes that contribute
        to overall health and well-being. It can be divided into 10 major
        components, each playing a crucial role in enhancing physical and mental
        performance.
      </p>
      <ul className="fitness-list">
        {fitnessTopics.map((topic, index) => (
          <li
            key={index}
            className={`fitness-item ${
              expandedIndex === index ? "active" : ""
            }`}
          >
            <div
              className="fitness-item-title"
              onClick={() => handleExpand(index)}
            >
              ➝ {topic.title}
            </div>
            {expandedIndex === index && (
              <div className="fitness-card expanded">
                {/* <h2 className="fitness-title">{topic.title}</h2> */}
                <p className="fitness-text">
                  ✅ Why We Need It? <span id="expandedspan">{topic.why}</span>
                </p>
                <p className="fitness-text">
                  ✅ Who Needs It Most?{" "}
                  <span id="expandedspan">{topic.who}</span>
                </p>
                <p className="fitness-text">
                  ✅ Ways to Achieve It:{" "}
                  <span id="expandedspan">{topic.how}</span>
                </p>
                <p className="fitness-text">
                  ✅ How to Level Up?
                  <span id="expandedspan">{topic.levelUp}</span>
                </p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FitnessCards;
