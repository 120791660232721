import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";
import { Link } from "react-scroll";
const Plans = () => {
  function whatsapp() {
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var phone = document.getElementById("phone").value;
    var age = document.getElementById("age").value;
    var plan = document.getElementById("plan").value;
    var message = document.getElementById("message").value;

    var url = "https://wa.me/+919654426556?text="
      + "*Name :* " + name + "%0a"
      + "*Email :* " + email + "%0a"
      + "*Contact No :* " + phone + "%0a"
      + "*Age :* " + age + "%0a"
      + "*Plan :* " + plan + "%0a"
      + "*Message :* " + message;

    window.open(url, '_blank').focus();
  }
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header" style={{ gap: "1rem" }}>
        <h3 className="stroke-text">Let's start your journey now withus</h3>
      </div>
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>₹ {plan.price}</span>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <Link className="btn"
              to="contact"
              spy={true}
              smooth={true}
            // onClick={whatsapp}
            >
              Join now
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
