import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/FEStamp.png";
import Bars from "../../assets/bars.png";
import "./Header.css";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const mobile = window.innerWidth <= 768;
  const menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Check if URL has path parameters (not home "/")
  const hasPathParams = location.pathname !== "/";

  // Handle clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpened(false);
      }
    };

    if (menuOpened) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("menu-open"); // Prevent scrolling when menu is open
    } else {
      document.body.classList.remove("menu-open");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("menu-open");
    };
  }, [menuOpened]);

  // Handle logo click: Reload only if the URL has path parameters
  const handleLogoClick = () => {
    if (hasPathParams) {
      navigate("/"); // Navigate to home
      setTimeout(() => {
        window.location.reload(); // Force reload after navigation
      }, 0);
    }
  };

  return (
    <div className="header" id="header">
      {/* Clicking the logo reloads only if path parameters exist */}
      <img src={Logo} alt="Logo" className="logo" onClick={handleLogoClick} />

      {/* Menu Toggle Button */}
      {mobile && !hasPathParams && (
        <div className="menu-icon" onClick={() => setMenuOpened((prev) => !prev)}>
          <img src={Bars} alt="menu-toggle" />
        </div>
      )}

      {/* Background Overlay */}
      {menuOpened && <div className="menu-overlay" onClick={() => setMenuOpened(false)}></div>}

      {/* Menu List (Hidden if URL has path parameters) */}
      {!hasPathParams && (
        <ul ref={menuRef} className={`header-menu ${menuOpened ? "open" : ""}`}>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="header" spy smooth>
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="programs" spy smooth>
              Programs
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="reasons" spy smooth>
              Why Us
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="plans" spy smooth>
              Plans
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="testimonials" spy smooth>
              Testimonials
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="contact" spy smooth>
              Contact Us
            </ScrollLink>
          </li>
          <li>
            <ScrollLink onClick={() => setMenuOpened(false)} to="about" spy smooth>
              About Us
            </ScrollLink>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
