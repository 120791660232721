import React, { useEffect, useState } from "react";
import "./FitnessTip.css";

const fitnessTips = [
  {
    tip: "Walking 10,000 steps a day can burn up to 500 calories! 🚶‍♂️",
    fact: "Regular walking also improves cardiovascular health and mental well-being.",
  },
  {
    tip: "Drinking water before meals can reduce calorie intake by 75-90 calories! 💧",
    fact: "Staying hydrated also boosts metabolism by up to 30%.",
  },
  {
    tip: "Just 30 minutes of daily exercise can add 3-5 years to your life! ⏰",
    fact: "Regular exercise reduces the risk of chronic diseases by up to 50%.",
  },
  {
    tip: "Muscle burns 3x more calories than fat at rest! 💪",
    fact: "Building muscle through strength training can boost your metabolism.",
  },
  {
    tip: "Post-workout protein within 30 minutes increases muscle growth by 33%! 🥚",
    fact: "The body's ability to rebuild muscle is highest right after exercise.",
  },
  {
    tip: "Morning workouts can boost metabolism by 10% throughout the day! 🌅",
    fact: "Exercise before breakfast helps burn more stored fat.",
  },
];

const FitnessTip = () => {
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTipIndex((prev) => (prev + 1) % fitnessTips.length);
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fitness-tip-container">
      <div className="fitness-tip-card">
        <div className="tip-header">💡 Fitness Tip of the Day</div>
        <div className="tip-content">
          <h3>{fitnessTips[currentTipIndex].tip}</h3>
          <p>{fitnessTips[currentTipIndex].fact}</p>
        </div>
        <div className="tip-footer">
          <div className="tip-dots">
            {fitnessTips.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentTipIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitnessTip;
