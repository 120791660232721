import React, { useEffect, useState, useRef } from "react";
import FitnessTip from "../FitnessTip/FitnessTip";

const AdSense = ({ slot, format = "auto", fullWidth = true }) => {
  const [isAdVisible, setIsAdVisible] = useState(true);
  const [showFallback, setShowFallback] = useState(false);
  const adRef = useRef(null);

  useEffect(() => {
    let adLoaded = false;
    let checkInterval;

    const checkAdLoaded = () => {
      if (adRef.current) {
        // Check if the ad iframe exists
        const adIframe = adRef.current.querySelector("iframe");
        if (adIframe) {
          adLoaded = true;
          clearInterval(checkInterval);
          return;
        }
      }
    };

    const timer = setTimeout(() => {
      try {
        if (window.adsbygoogle && adRef.current) {
          // Push the ad
          window.adsbygoogle.push({});

          // Start checking for ad load
          checkInterval = setInterval(() => {
            checkAdLoaded();
          }, 100);

          // If no ad loads after 5 seconds, show fallback
          setTimeout(() => {
            if (!adLoaded) {
              console.log("No ad loaded after timeout");
              setShowFallback(true);
              setIsAdVisible(false);
            }
            clearInterval(checkInterval);
          }, 5000);
        }
      } catch (err) {
        console.error("Error loading AdSense:", err);
        setShowFallback(true);
        setIsAdVisible(false);
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(checkInterval);
    };
  }, []);

  // Show fallback if ad is not visible or failed to load
  if (!isAdVisible || showFallback) {
    return <FitnessTip />;
  }

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{
          display: "block",
          width: "100%",
          minHeight: "280px",
          backgroundColor: "#f9f9f9",
          margin: "20px 0",
        }}
        data-ad-client="ca-pub-9840131806326657"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={fullWidth}
      />
    </div>
  );
};

export default AdSense;
