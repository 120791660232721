import React from 'react'
import "./Programs.css"
import { programsData } from "../../data/programsData"
import RightArrow from "../../assets/rightArrow.png"
import { Link } from "react-scroll";

const Programs = () => {
    function whatsapp() {
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var age = document.getElementById("age").value;
        var plan = document.getElementById("plan").value;
        var message = document.getElementById("message").value;

        var url = "https://wa.me/+919654426556?text="
            + "*Name :* " + name + "%0a"
            + "*Email :* " + email + "%0a"
            + "*Contact No :* " + phone + "%0a"
            + "*Age :* " + age + "%0a"
            + "*Plan :* " + plan + "%0a"
            + "*Message :* " + message;

        window.open(url, '_blank').focus();
    }
    return (
        <div className="Programs" id="programs">
            <div className="programs-header">
                <h3 className='stroke-text'>Explore Our Programs to shape you</h3>
                {/* <span className='stroke-text'>Programs</span>
                <span className='stroke-text'>to shape you</span> */}
            </div>
            <div className="programs-categories">
                {programsData.map((program) => (
                    <div className="category">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join-now">
                            <Link className="btn"
                                to="contact"
                                spy={true}
                                smooth={true}
                            // onClick={whatsapp}
                            >
                                Join now
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default Programs
