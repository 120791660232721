import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import AdSense from "../AdSense/AdSense";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Footer-container" id="about">
        <section className="footer">
          <div className="box-container">
            <div className="box">
              <p className="address">Locate Us</p>
              <a href="https://maps.app.goo.gl/8NGDHNC2vSXQJWu19">
                Klassik Landmark, Kasavanahalli, Bengaluru
              </a>
            </div>
            <div className="box">
              <p className="footer-contact">Contact Us</p>

              <div className="contact-item">
                <a href="tel:+919654426556"> 📱 +91 96544 26556</a>
              </div>

              <div className="contact-item">
                <a href="mailto:fitnessengineer@gmail.com">
                  {" "}
                  📧 fitnessengineer@gmail.com
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="credits">
          <p className="para"> All Rights Reserved.</p>
          <button
            className="info-button"
            onClick={() => navigate("/privacy-and-legal-policies")}
          >
            i
          </button>
        </div>

        {/* AdSense Ads */}
        <div className="footer-ads">
          <AdSense slot="1771564474" format="auto" fullWidth={true} />
          <AdSense slot="4820399641" format="autorelaxed" />
        </div>
      </div>
    </>
  );
};

export default Footer;
