import React, { useState } from "react";
import "./CalculateYourself.css";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/FEStamp.png";

const CalculateYourself = () => {
  const [calculationType, setCalculationType] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [neck, setNeck] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [lifestyle, setLifestyle] = useState("");
  const [result, setResult] = useState(null);
  const [unit, setUnit] = useState("");
  const [message, setMessage] = useState("");
  const [showJoinUs, setShowJoinUs] = useState(false);
  const [suggestion, setSuggestion] = useState("");

  const handleLogoClick = () => {
    navigate("/");
  };

  const location = useLocation();
  const navigate = useNavigate();

  const calculate = () => {
    if (!age || !weight || !height || !calculationType || !gender) {
      alert("Please fill in all required fields.");
      return;
    }

    let calculatedResult = 0;
    let resultUnit = "";
    let userMessage =
      "Congratulations! You’ve taken the first step toward a tailored fitness experience.";

    switch (calculationType) {
      case "bmr":
        calculatedResult =
          gender === "male"
            ? 88.36 + 13.4 * weight + 4.8 * height - 5.7 * age
            : 447.6 + 9.2 * weight + 3.1 * height - 4.3 * age;
        resultUnit = "calories/day";
        break;

      case "bmi":
        calculatedResult = weight / (height / 100) ** 2;
        resultUnit = "kg/m²";
        break;

      case "bodyFat":
        if (!neck || !waist || (gender === "female" && !hip)) {
          alert("Please fill in all required measurements.");
          return;
        }
        const neckValue = parseFloat(neck);
        const waistValue = parseFloat(waist);
        const hipValue = parseFloat(hip);
        const heightValue = parseFloat(height);

        if (gender === "male") {
          calculatedResult =
            86.01 * Math.log10(waistValue - neckValue) -
            70.041 * Math.log10(heightValue) +
            36.76;
        } else {
          calculatedResult =
            163.205 * Math.log10(waistValue + hipValue - neckValue) -
            97.684 * Math.log10(heightValue) -
            104.912;
        }
        resultUnit = "% body fat";
        break;

      case "calorieIntake":
        if (!lifestyle) {
          alert("Please select your lifestyle.");
          return;
        }
        const bmr =
          gender === "male"
            ? 88.36 + 13.4 * weight + 4.8 * height - 5.7 * age
            : 447.6 + 9.2 * weight + 3.1 * height - 4.3 * age;
        calculatedResult = bmr * getActivityMultiplier(lifestyle);
        resultUnit = "calories/day";
        break;

      default:
        break;
    }

    const getSuggestions = (result, type, lifestyle) => {
      if (type === "bmi") {
        if (result < 18.5)
          return "You're underweight. Focus on a balanced diet with protein-rich foods and healthy fats.";
        if (result >= 18.5 && result < 24.9)
          return "You're in the normal range! Maintain a balanced diet and regular exercise.";
        if (result >= 25 && result < 29.9)
          return "You're overweight. Consider a calorie deficit diet and more physical activity.";
        if (result >= 30)
          return "You're in the obese category. Consult a professional for a structured diet and exercise plan.";
      }

      if (type === "calorieIntake") {
        switch (lifestyle) {
          case "sedentary":
            return "Your lifestyle is sedentary. Try to incorporate daily walks and light exercises.";
          case "light":
            return "You're lightly active. Consider increasing your physical activity to improve health.";
          case "moderate":
            return "Good job! A moderate activity level is beneficial. Keep up the balanced routine.";
          case "active":
            return "You're very active. Make sure you're consuming enough nutrients to fuel your energy.";
          case "athlete":
            return "You're training like an athlete! Keep focusing on recovery and proper nutrition.";
          default:
            return "";
        }
      }

      if (type === "bodyFat") {
        if (gender === "male") {
          if (result < 6)
            return "You're at an essential fat level. Ensure you're consuming enough nutrients.";
          if (result >= 6 && result < 14)
            return "You're in the athlete category. Maintain a protein-rich diet.";
          if (result >= 14 && result < 24)
            return "You're in the fitness range. Keep up the good work!";
          if (result >= 24)
            return "Your body fat is high. Consider adjusting your diet and increasing exercise.";
        } else {
          if (result < 14)
            return "You're at an essential fat level. Ensure you're consuming enough nutrients.";
          if (result >= 14 && result < 21)
            return "You're in the athlete category. Maintain a balanced diet.";
          if (result >= 21 && result < 32)
            return "You're in the fitness range. Keep up the good work!";
          if (result >= 32)
            return "Your body fat is high. Consider adjusting your diet and increasing exercise.";
        }
      }

      return "Keep focusing on your fitness journey and maintaining a healthy lifestyle!";
    };

    setResult(calculatedResult.toFixed(2));
    setUnit(resultUnit);
    setMessage(userMessage);
    setSuggestion(getSuggestions(calculatedResult, calculationType, lifestyle));
    setShowJoinUs(true);
  };

  const whatsappConnect = () => {
    let message =
      "🏋️‍♂️ Welcome to Fitness Engineers! 🎯\n\n" +
      "Hey there! It looks like you've explored our health & fitness calculators and are taking the first step towards a healthier you. 💪\n\n" +
      "Here are your details:\n\n" +
      `✅ *Age:* ${age} years\n` +
      `✅ *Gender:* ${gender}\n` +
      `✅ *Height:* ${height} cms\n` +
      `✅ *Weight:* ${weight} kgs\n` +
      (neck ? `✅ *Neck:* ${neck} cms\n` : "") +
      (waist ? `✅ *Waist:* ${waist} cms\n` : "") +
      (hip ? `✅ *Hip:* ${hip} cms\n` : "") +
      `✅ *Lifestyle:* ${lifestyle}\n\n` +
      "It seems like you’re looking for expert guidance to achieve your fitness goals. Whether it's weight management, muscle gain, or overall well-being, **our team of fitness professionals is here to help!** 🏆\n\n" +
      "🚀 Let's turn your health goals into reality! Our experts will connect with you to create a **personalized diet & workout plan** tailored just for you. 🔥\n\n" +
      "Looking forward to supporting you on your journey to a stronger, healthier you! 💪💯";

    let encodedMessage = encodeURIComponent(message);

    let url = `https://wa.me/+919654426556?text=${encodedMessage}`;

    window.open(url, "_blank").focus();
  };

  const getActivityMultiplier = (lifestyle) => {
    switch (lifestyle) {
      case "sedentary":
        return 1.2;
      case "light":
        return 1.375;
      case "moderate":
        return 1.55;
      case "active":
        return 1.725;
      case "athlete":
        return 1.9;
      default:
        return 1.2;
    }
  };

  return (
    <div className="calculate-yourself">
      <div className="content">
        <img
          src={Logo}
          alt="Logo"
          className="callogo"
          onClick={handleLogoClick}
        />
        <h2>Health & Fitness Calculators</h2>
      </div>
      <p>Select the type of measurement you want to calculate.</p>
      <div className="calculator">
        <label>Choose Calculator:</label>
        <select
          value={calculationType}
          onChange={(e) => setCalculationType(e.target.value)}
        >
          <option value="">Select...</option>
          <option value="bmr">BMR Calculator</option>
          <option value="bmi">BMI Calculator</option>
          <option value="bodyFat">Body Fat Percentage</option>
          <option value="calorieIntake">Calorie Intake</option>
        </select>

        <label>Gender:</label>
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">Select...</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>

        <label>Age (years):</label>
        <input
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />

        <label>Weight (kg):</label>
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />

        <label>Height (cm):</label>
        <input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />

        {calculationType === "bodyFat" && (
          <>
            <label>Neck (cm):</label>
            <input
              type="number"
              value={neck}
              onChange={(e) => setNeck(e.target.value)}
            />
            <label>Waist (cm):</label>
            <input
              type="number"
              value={waist}
              onChange={(e) => setWaist(e.target.value)}
            />
            {gender === "female" && (
              <>
                <label>Hip (cm):</label>
                <input
                  type="number"
                  value={hip}
                  onChange={(e) => setHip(e.target.value)}
                />
              </>
            )}
          </>
        )}

        <label>Lifestyle:</label>
        <select
          value={lifestyle}
          onChange={(e) => setLifestyle(e.target.value)}
        >
          <option value="">Select...</option>
          <option value="sedentary">Sedentary (Little to No Exercise)</option>
          <option value="light">Lightly Active (1-3 days/week)</option>
          <option value="moderate">Moderately Active (3-5 days/week)</option>
          <option value="active">Very Active (6-7 days/week)</option>
          <option value="athlete">Athlete (Extreme Training)</option>
        </select>

        <button onClick={calculate}>Calculate</button>

        {result !== null && (
          <div className="result">
            <h3>Your Result:</h3>
            <p>
              {result} {unit}
            </p>
            <p>{message}</p>
            <h4>💡 Suggestion:</h4>
            <p>{suggestion}</p>

            <div className="join-us">
              <h3>Want a Personalized Plan?</h3>
              <p>
                Join us today and get a tailored diet & workout plan just for
                you!
              </p>
              <button onClick={whatsappConnect}>Join Now</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculateYourself;
