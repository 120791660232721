import React from "react";
import "./Contact.css";

const Contact = () => {
  function whatsapp() {
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var phone = document.getElementById("phone").value;
    var age = document.getElementById("age").value;
    var plan = document.getElementById("plan").value;
    var message = document.getElementById("message").value;

    // Check if values are not empty
    if (!name || !email || !phone || !age || !plan || !message) {
      alert("Please fill out all the fields.");
      return;
    }

    var url =
      "https://wa.me/+919654426556?text=" +
      "🏋️‍♂️ Welcome to Fitness Engineers! 🎯\n\n" +
      "Hey there! We're excited to have you on your fitness journey with us. 💪\n\n" +
      "Please provide your details:\n\n" +
      "✅ *Name:* " +
      name +
      "\n" +
      "✅ *Email:* " +
      email +
      "\n" +
      "✅ *Contact No:* " +
      phone +
      "\n" +
      "✅ *Age:* " +
      age +
      "\n" +
      "✅ *Selected Plan:* " +
      plan +
      "\n" +
      "✅ *Message:* " +
      message +
      "\n\n" +
      "Once you've shared these details, our fitness experts will connect with you to create a personalized plan tailored to your goals. 💪 We'll guide you every step of the way! 🚀\n\n" +
      "Looking forward to supporting you on your journey to a stronger, healthier you! 🔥";
    console.log("url", url);

    window.open(url, "_blank").focus();
  }

  return (
    <div className="container" id="contact">
      <div className="screen">
        <div className="screen-header">
          <div className="screen-header-left">
            <div className="screen-header-button close"></div>
            <div className="screen-header-button maximize"></div>
            <div className="screen-header-button minimize"></div>
          </div>
          <div className="screen-header-right">
            <div className="screen-header-ellipsis"></div>
            <div className="screen-header-ellipsis"></div>
            <div className="screen-header-ellipsis"></div>
          </div>
        </div>
        <div className="screen-body">
          <div className="screen-body-item left">
            <div className="app-title">
              <span>CONTACT US</span>
            </div>
            <div className="app-contact">
              To provide you with the best experience, we'd love to know more
              about you. Please share your details with us, so we can assist you
              better.
            </div>
          </div>
          <div className="screen-body-item">
            <div className="app-form">
              <div className="app-form-group">
                <input
                  className="app-form-control"
                  autoComplete="true"
                  placeholder="NAME"
                  id="name"
                />
              </div>
              <div className="app-form-group">
                <input
                  className="app-form-control"
                  autoComplete="on"
                  placeholder="EMAIL"
                  id="email"
                />
              </div>
              <div className="app-form-group">
                <input
                  className="app-form-control"
                  placeholder="CONTACT NO"
                  id="phone"
                />
              </div>
              <div className="app-form-group">
                <input
                  className="app-form-control"
                  placeholder="Age"
                  id="age"
                />
              </div>
              <div className="app-form-group">
                <input
                  className="app-form-control"
                  placeholder="Plan"
                  id="plan"
                />
              </div>
              <div className="app-form-group message">
                <input
                  className="app-form-control"
                  placeholder="MESSAGE"
                  id="message"
                />
              </div>
              <div className="app-form-group buttons">
                <button className="app-form-button" data-bs-dismiss="modal">
                  CANCEL
                </button>
                <button className="app-form-button" onClick={whatsapp}>
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
